import {
  tableState,
  tableMutations,
  tableActions,
  tableGetters
} from "@tt/vue-components";
import InventoryService from "@/services/InventoryService";
import statusFilter from "@/store/modules/storeReservations/filters/statusFilter";
import skuFilter from "@/store/modules/storeReservations/filters/skuFilter";
import descriptionFilter from "@/store/modules/storeReservations/filters/descriptionFilter";

const storeReservationsOverview = {
  requestController: null,
  namespaced: true,
  state: {
    ...tableState,
    reservationItem: null
  },
  modules: {
    descriptionFilter,
    skuFilter,
    statusFilter
  },
  mutations: {
    ...tableMutations,
    SET_RESERVATION_ITEM(state, item) {
      state.reservationItem = item;
    }
  },
  actions: {
    ...tableActions,
    fetchItems({ state, commit }) {
      commit("SET_LOADING", true);
      const params = {
        pagination: state.pagination,
        sorting: state.sorting,
        search: [ ...state.search, { column: "reservation.reservationGroup.code@exact[]", value: "reservation" } ]
      };
      if (this.requestController) {
        this.requestController.abort();
      }
      this.requestController = new AbortController();

      InventoryService.reservationItems.list(params, { signal: this.requestController.signal })
        .then(json => {
          if (json) {
            commit("SET_ITEMS", json);
          }
        })
        .catch(err => {
          console.log(err);
        })
        .finally(() => {
          commit("SET_LOADING", false);
        });
    },
    patch({commit}, reservationItem) {
      return new Promise((resolve, reject) => {
        InventoryService.reservationItems.patch(reservationItem.id, reservationItem.body)
          .then((data) => {
            commit("EDIT_ITEM", data);
            resolve();
          })
          .catch(err => {
            reject(err["hydra:description"]);
          });
      });
    },
    selectReservation({commit}, item) {
      if (item !== null) {
        commit("SET_RESERVATION_ITEM", item);
        commit("SET_ITEM", item);
      } else {
        commit("SET_RESERVATION_ITEM", null);
        commit("SET_ITEM", null);
      }
    },
  },
  getters: {
    ...tableGetters
  }
};

export default storeReservationsOverview;
